import { defineStore } from 'pinia';

interface IBansStore {

}

export const useBansStore = defineStore('bansStore', {
  state: (): IBansStore => ({

  }),
  getters: {

  },
  actions: {

  },
});
