import type { IAgent, ICollectible, IGlovesData, IMusicKit, ISkin } from '~/types/skins';
import type { ISearchParams, IServer, IServerStatistics, ISuspensionRes, IRanksRes, IUserData, IPlayerSkinsRes, IPlayerSkinReq, IPlayerSkinRes, TWsItems } from '~/types/types';

export default defineNuxtPlugin(() => {
  const $useApi = $fetch.create({
    baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:3000/api' : 'https://cs2master.eu/api',
    headers: {
      Accept: 'application/json',
    },
    cache: 'no-cache',
    credentials: 'include',
    onResponseError: async err => {
      const errorData = await err.response._data;
      helpers.error.handleFetchError(errorData);
    },
  });

  const api = {
    index: {
      getHomepage() {
        return $useApi('/homepage');
      },
    },
    bans: {
      getBansList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/bans', { method: 'POST', body });
      },
    },
    mutes: {
      getMutesList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/mutes', { method: 'POST', body });
      },
    },
    warnings: {
      getWarningsList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/warns', { method: 'POST', body });
      },
    },
    servers: {
      getServersList(): Promise<{ servers: IServer[] }> {
        return $useApi('/servers');
      },
      getServerStats(serverId: number): Promise<IServerStatistics> {
        return $useApi(`/servers/${serverId}`);
      },
      getTopPlayers(): Promise<IRanksRes> {
        return $useApi('/ranks/top');
      },
    },
    auth: {
      login(): Promise<void> {
        return $useApi('/auth');
      },
      userData(): Promise<IUserData> {
        return $useApi('/auth/user');
      },
      purchase(productId: number): Promise<{ url: string }> {
        return $useApi('/stripe/checkout', { method: 'POST', body: { productId } });
      },
      playerSkins(): Promise<IPlayerSkinsRes> {
        return $useApi('/auth/skins');
      },
      upsertSkin(body: IPlayerSkinReq): Promise<IPlayerSkinRes> {
        return $useApi('/auth/skin', { method: 'PATCH', body });
      },
      upsertKnife(name: string) {
        return $useApi('/auth/knife', { method: 'PATCH', body: { name } });
      },
      upsertMusicKit(kitId: number) {
        return $useApi('/auth/musickit', { method: 'PATCH', body: { kitId } });
      },
      upsertGloves(defIndex: number, paintId: number) {
        return $useApi('/auth/gloves', { method: 'PATCH', body: { defIndex, paintId } });
      },
      upsertAgent(model: string, team: string) {
        return $useApi('/auth/agent', { method: 'PATCH', body: { model, team } });
      },
      upsertCollectible(pinId: number) {
        return $useApi('/auth/collectible', { method: 'PATCH', body: { pinId } });
      },
      removeItem(type: TWsItems, id: number | string) {
        return $useApi(`/auth/${type}/${id}`, { method: 'DELETE' });
      },
    },
    skins: {
      skinsData(): Promise<ISkin[]> {
        return $fetch('https://bymykel.github.io/CSGO-API/api/en/skins.json');
      },
      loadAgents(): Promise<IAgent[]> {
        return $fetch('https://bymykel.github.io/CSGO-API/api/en/agents.json');
      },
      loadMusicKits(): Promise<IMusicKit[]> {
        return $fetch('https://bymykel.github.io/CSGO-API/api/en/music_kits.json');
      },
      loadCollectibles(): Promise<ICollectible[]> {
        return $fetch('https://bymykel.github.io/CSGO-API/api/en/collectibles.json');
      },
      loadGloves(): Promise<IGlovesData[]> {
        return $fetch('/data/gloves.json');
      },
    },
  };

  return {
    provide: {
      api,
    },
  };
});
