import { defineStore } from 'pinia';

interface IWarningsStore {

}

export const useWarningsStore = defineStore('warningsStore', {
  state: (): IWarningsStore => ({

  }),
  getters: {

  },
  actions: {

  },
});
