export default defineNuxtPlugin(async () => {
  const { $store, $api } = useNuxtApp();

  try {
    $store.index.loadingUserData = true;
    if (process.client) {
      window.addEventListener('resize', function () {
        $store.index.screenWidth = this.innerWidth;
      });
      $store.index.screenWidth = window.innerWidth;

      const steamCookie = useCookie('loggedIn');
      const [ servers, user ] = await Promise.allSettled([
        $api.servers.getServersList(),
        $api.auth.userData(),
      ]);

      if (servers.status === 'fulfilled') {
        $store.index.servers = servers.value.servers;
        steamCookie.value = 'true';
      }
      if (user.status === 'fulfilled') {
        $store.index.user = user.value;
      } else if (steamCookie.value) {
        steamCookie.value = null;
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    $store.index.loadingUserData = false;
  }
});
