import { useIndexStore } from '~/pinia';
import { useBansStore } from '~/pinia/bans';
import { useMutesStore } from '~/pinia/mutes';
import { useWarningsStore } from '~/pinia/warningsStore';

interface IStore {
  index: ReturnType<typeof useIndexStore>;
  bans: ReturnType<typeof useBansStore>;
  mutes: ReturnType<typeof useMutesStore>;
  warnings: ReturnType<typeof useWarningsStore>;
}

export default defineNuxtPlugin(() => {
  const ctx = useNuxtApp();

  const store: IStore = {
    index: useIndexStore(ctx.$pinia),
    bans: useBansStore(ctx.$pinia),
    mutes: useMutesStore(ctx.$pinia),
    warnings: useWarningsStore(ctx.$pinia),
  };

  return {
    provide: {
      store,
    },
  };
});
