import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppGoBack, LazyAppHomeBackground, LazyAppInput, LazyAppLoader, LazyAppModal, LazyAppNavigation, LazyAppServerList, LazyAppSpinner, LazyAppSpinnerImage, LazyAppStatusPill, LazyAppSuspensionList, LazyAppToasts, LazyAppTooltip, LazyAppSkinsSkin, LazyAppSkinsWeapon, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AppGoBack", LazyAppGoBack],
["AppHomeBackground", LazyAppHomeBackground],
["AppInput", LazyAppInput],
["AppLoader", LazyAppLoader],
["AppModal", LazyAppModal],
["AppNavigation", LazyAppNavigation],
["AppServerList", LazyAppServerList],
["AppSpinner", LazyAppSpinner],
["AppSpinnerImage", LazyAppSpinnerImage],
["AppStatusPill", LazyAppStatusPill],
["AppSuspensionList", LazyAppSuspensionList],
["AppToasts", LazyAppToasts],
["AppTooltip", LazyAppTooltip],
["AppSkinsSkin", LazyAppSkinsSkin],
["AppSkinsWeapon", LazyAppSkinsWeapon],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
