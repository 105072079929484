import { defineStore } from 'pinia';
import type { IAgent, ICollectible, IGloves, IMusicKit, ISkin } from '~/types/skins';
import type { IPlayerSkinReq, IPlayerSkinsRes, IServer, IToast, IUserData } from '~/types/types';

interface IIndexStore {
  servers: IServer[];
  loadingUserData: boolean;
  user: IUserData | null;
  toast: IToast[];
  screenWidth: number;
  dev: boolean;
  loadingSkinsData: boolean;
  skins: ISkin[];
  loadingAgentsData: boolean;
  agents: IAgent[];
  loadingMusicKitsData: boolean;
  musicKits: IMusicKit[];
  loadingCollectiblesData: boolean;
  collectibles: ICollectible[];
  loadingGloves: boolean;
  gloves: IGloves[];
  loadingPlayerSkins: boolean;
  playerSkins: IPlayerSkinsRes | null;
}

export const useIndexStore = defineStore('indexStore', {
  state: (): IIndexStore => ({
    servers: [],
    loadingUserData: false,
    user: null,
    toast: [],
    screenWidth: 661,
    dev: process?.env?.NODE_ENV === 'development' || false,
    loadingSkinsData: false,
    skins: [],
    loadingAgentsData: false,
    agents: [],
    loadingMusicKitsData: false,
    musicKits: [],
    loadingCollectiblesData: false,
    collectibles: [],
    loadingGloves: false,
    gloves: [],
    loadingPlayerSkins: false,
    playerSkins: null,
  }),
  getters: {

  },
  actions: {
    async loadSkinsData() {
      if (this.loadingSkinsData || this.skins.length) {
        return this.skins;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        this.loadingSkinsData = true;
        this.skins = await $api.skins.skinsData();
        return this.skins;
      } catch (err) {
        $toast.error('errors.error', err);
      } finally {
        this.loadingSkinsData = false;
      }
    },
    async getPlayerSkins(reload = false) {
      if (this.loadingPlayerSkins) {
        return null;
      }

      const { $api, $toast } = useNuxtApp();
      try {
        if (!this.playerSkins || reload) {
          this.loadingPlayerSkins = true;
          this.playerSkins = await $api.auth.playerSkins();
        }
        return helpers.mapPlayerSkins(this.playerSkins, this.skins);
      } catch (err) {
        $toast.error('errors.error', err);
        return null;
      } finally {
        this.loadingPlayerSkins = false;
      }
    },
    async getSkinsById(id: string) {
      return this.skins
        .filter(skin => skin.weapon.id === id)
        .sort((a, b) => {
          const rarityDiff = objects.rarityOrder.indexOf(a.rarity.name) - objects.rarityOrder.indexOf(b.rarity.name);
          if (rarityDiff === 0) {
            return a.name < b.name ? -1 : 1;
          }
          return rarityDiff;
        });
    },
    async getAgentsList() {
      if (this.loadingAgentsData || this.agents.length) {
        return this.agents;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        this.loadingAgentsData = true;
        const agentsRes = await $api.skins.loadAgents();
        this.agents = agentsRes.sort((a, b) => {
          const rarityDiff = objects.rarityOrder.indexOf(a.rarity.name) - objects.rarityOrder.indexOf(b.rarity.name);
          if (rarityDiff === 0) {
            return a.name < b.name ? -1 : 1;
          }
          return rarityDiff;
        });
        return this.agents;
      } catch (err) {
        $toast.error('errors.error', err);
        return [];
      } finally {
        this.loadingAgentsData = false;
      }
    },
    async getMusicKitsList() {
      if (this.loadingMusicKitsData || this.musicKits.length) {
        return this.musicKits;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        this.loadingMusicKitsData = true;
        const musicKitsRes = await $api.skins.loadMusicKits();
        this.musicKits = musicKitsRes
          .filter(kit => !kit.id.includes('_st'))
          .sort((a, b) => a.name.replace('Music Kit | ', '') < b.name.replace('Music Kit | ', '') ? -1 : 1);
        return this.musicKits;
      } catch (err) {
        $toast.error('errors.error', err);
        return [];
      } finally {
        this.loadingMusicKitsData = false;
      }
    },
    async getCollectiblesList() {
      if (this.loadingCollectiblesData || this.collectibles.length) {
        return this.collectibles;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        this.loadingCollectiblesData = true;
        const collectiblesRes = await $api.skins.loadCollectibles();
        this.collectibles = collectiblesRes.sort((a, b) => a.name < b.name ? -1 : 1);
        return this.collectibles;
      } catch (err) {
        $toast.error('errors.error', err);
        return [];
      } finally {
        this.loadingCollectiblesData = false;
      }
    },
    async getGlovesList() {
      if (this.loadingGloves || this.gloves.length) {
        return this.gloves;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        this.loadingGloves = true;
        const glovesData = await $api.skins.loadGloves();
        this.gloves = glovesData.map(gloves => ({
          id: `gloves-${gloves.weapon_defindex}-${gloves.paint}`,
          image: gloves.image,
          defIndex: gloves.weapon_defindex,
          name: gloves.paint_name,
          paint_id: +gloves.paint,
          rarity: {
            name: 'Covert',
            color: '#eb4b4b',
          },
        }));
        return this.gloves;
      } catch (err) {
        $toast.error('errors.error', err);
        return [];
      } finally {
        this.loadingGloves = false;
      }
    },
    async updateSkin(defIndex: number, skin: ISkin) {
      if (!this.playerSkins) {
        return;
      }
      const { $api, $toast } = useNuxtApp();
      try {
        const body: IPlayerSkinReq = {
          defIndex,
          paintId: +(skin.paint_index || 0),
        };
        const newSkin = await $api.auth.upsertSkin(body);
        const oldSkinIdx = this.playerSkins.skins.findIndex(s => s.weapon_defindex === newSkin.weapon_defindex);
        if (typeof oldSkinIdx === 'number' && oldSkinIdx > -1) {
          this.playerSkins.skins[oldSkinIdx] = newSkin;
        } else {
          this.playerSkins.skins.push(newSkin);
        }
      } catch (err) {
        $toast.error('Error', err);
      }
    },
  },
});
