export default {
  vipPlans: {
    0: 'Mini VIP',
    1: 'VIP',
    2: 'Admin',
  },
  weapons: {
    1: { id: 'weapon_deagle', name: 'Desert Eagle' },
    2: { id: 'weapon_elite', name: 'Dual Berettas' },
    3: { id: 'weapon_fiveseven', name: 'Five-SeveN' },
    4: { id: 'weapon_glock', name: 'Glock-18' },
    7: { id: 'weapon_ak47', name: 'AK-47' },
    8: { id: 'weapon_aug', name: 'AUG' },
    9: { id: 'weapon_awp', name: 'AWP' },
    10: { id: 'weapon_famas', name: 'FAMAS' },
    11: { id: 'weapon_g3sg1', name: 'G3SG1' },
    13: { id: 'weapon_galilar', name: 'Galil AR' },
    14: { id: 'weapon_m249', name: 'M249' },
    16: { id: 'weapon_m4a1', name: 'M4A1' },
    17: { id: 'weapon_mac10', name: 'MAC-10' },
    19: { id: 'weapon_p90', name: 'P90' },
    23: { id: 'weapon_mp5sd', name: 'MP5-SD' },
    24: { id: 'weapon_ump45', name: 'UMP-45' },
    25: { id: 'weapon_xm1014', name: 'XM1014' },
    26: { id: 'weapon_bizon', name: 'PP-Bizon' },
    27: { id: 'weapon_mag7', name: 'MAG-7' },
    28: { id: 'weapon_negev', name: 'Negev' },
    29: { id: 'weapon_sawedoff', name: 'Sawed-Off' },
    30: { id: 'weapon_tec9', name: 'Tec-9' },
    31: { id: 'weapon_taser', name: 'Zeus x27' },
    32: { id: 'weapon_hkp2000', name: 'P2000' },
    33: { id: 'weapon_mp7', name: 'MP7' },
    34: { id: 'weapon_mp9', name: 'MP9' },
    35: { id: 'weapon_nova', name: 'Nova' },
    36: { id: 'weapon_p250', name: 'P250' },
    38: { id: 'weapon_scar20', name: 'SCAR-20' },
    39: { id: 'weapon_sg556', name: 'SG 553' },
    40: { id: 'weapon_ssg08', name: 'SSG 08' },
    60: { id: 'weapon_m4a1_silencer', name: 'M4A1-S' },
    61: { id: 'weapon_usp_silencer', name: 'USP-S' },
    63: { id: 'weapon_cz75a', name: 'CZ75-Auto' },
    64: { id: 'weapon_revolver', name: 'R8 Revolver' },
  },
  knives: {
    500: { id: 'weapon_bayonet', name: 'Bayonet' },
    503: { id: 'weapon_knife_css', name: 'Classic Knife' },
    505: { id: 'weapon_knife_flip', name: 'Flip Knife' },
    506: { id: 'weapon_knife_gut', name: 'Gut Knife' },
    507: { id: 'weapon_knife_karambit', name: 'Karambit' },
    508: { id: 'weapon_knife_m9_bayonet', name: 'M9 Bayonet' },
    509: { id: 'weapon_knife_tactical', name: 'Huntsman Knife' },
    512: { id: 'weapon_knife_falchion', name: 'Falchion Knife' },
    514: { id: 'weapon_knife_survival_bowie', name: 'Bowie Knife' },
    515: { id: 'weapon_knife_butterfly', name: 'Butterfly Knife' },
    516: { id: 'weapon_knife_push', name: 'Shadow Daggers' },
    517: { id: 'weapon_knife_cord', name: 'Paracord Knife' },
    518: { id: 'weapon_knife_canis', name: 'Survival Knife' },
    519: { id: 'weapon_knife_ursus', name: 'Ursus Knife' },
    520: { id: 'weapon_knife_gypsy_jackknife', name: 'Navaja Knife' },
    521: { id: 'weapon_knife_outdoor', name: 'Nomad Knife' },
    522: { id: 'weapon_knife_stiletto', name: 'Stiletto Knife' },
    523: { id: 'weapon_knife_widowmaker', name: 'Talon Knife' },
    525: { id: 'weapon_knife_skeleton', name: 'Skeleton Knife' },
    526: { id: 'weapon_knife_kukri', name: 'Kukri Knife' },
  },
  rarityOrder: [
    'Consumer Grade',
    'Industrial Grade',
    'Mil-Spec Grade',
    'Distinguished',
    'Restricted',
    'Exceptional',
    'Classified',
    'Superior',
    'Covert',
    'Master',
    'Extraordinary',
    'Contraband',
  ],
};
