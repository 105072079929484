export default defineNuxtRouteMiddleware((to) => {
  const { $store, $toast } = useNuxtApp();
  const protectedRoutes = [ '/user', '/skins' ];

  if (process.client) {
    if (!$store.index.loadingUserData && protectedRoutes.some((prot) => to.path.startsWith(prot)) && !$store.index.user) {
      $toast.error('You must be logged in');
      return navigateTo('/');
    }

    if (to.path.startsWith('/payment') && !('success' in to.query || 'cancelled' in to.query)) {
      return navigateTo('/');
    }
  }
});
