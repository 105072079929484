import { defineStore } from 'pinia';

interface IMutesStore {

}

export const useMutesStore = defineStore('mutesStore', {
  state: (): IMutesStore => ({

  }),
  getters: {

  },
  actions: {

  },
});
