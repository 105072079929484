<template>
  <NuxtLayout>
    <div class="template">
      <app-navigation />
      <main>
        <div class="page">
          <NuxtPage />
        </div>

        <footer>
          <div class="flex-col gap-1">
            <NuxtLink to="/" class="main">Home</NuxtLink>
            <NuxtLink to="/servers" class="main">Servers</NuxtLink>
            <NuxtLink to="/vip" class="main">VIP</NuxtLink>
            <NuxtLink to="/skins" class="main">Skins</NuxtLink>
            <NuxtLink to="/attributions" class="main">Attributions</NuxtLink>
          </div>

          <div class="flex-col gap-1">
            <p class="title">Suspensions</p>
            <NuxtLink to="/bans/list">Bans list</NuxtLink>
            <NuxtLink to="/bans/muted">Mutes list</NuxtLink>
            <NuxtLink to="/bans/warnings">Warnings list</NuxtLink>
          </div>
        </footer>
      </main>
    </div>
    <app-toasts />
  </NuxtLayout>
</template>

<script setup lang="ts">
</script>

<style lang="sass">
.page
  min-height: 100vh

footer
  padding: 5rem 3rem
  background: #224
  margin: 0 -2rem
  display: flex
  gap: 3rem
  justify-content: space-evenly
  font-size: 1.6rem
  color: #fff

  .title
    color: #ffaa00
    font-weight: 500

  .main
    font-weight: 500
</style>
