import { default as indexR8f0zt2aNOMeta } from "D:/DEV/CS2/web/pages/bans/index.vue?macro=true";
import { default as list5te8W3SGtzMeta } from "D:/DEV/CS2/web/pages/bans/list.vue?macro=true";
import { default as mutedCaUHTIeNUzMeta } from "D:/DEV/CS2/web/pages/bans/muted.vue?macro=true";
import { default as warningsshl78U3K8fMeta } from "D:/DEV/CS2/web/pages/bans/warnings.vue?macro=true";
import { default as indexrJfJ8hjmAHMeta } from "D:/DEV/CS2/web/pages/index.vue?macro=true";
import { default as paymentNiz7QSmUb6Meta } from "D:/DEV/CS2/web/pages/payment.vue?macro=true";
import { default as serversvo3pMhn0iCMeta } from "D:/DEV/CS2/web/pages/servers.vue?macro=true";
import { default as indexOsyXMNEofdMeta } from "D:/DEV/CS2/web/pages/skins/index.vue?macro=true";
import { default as tops4phXzlPOsMeta } from "D:/DEV/CS2/web/pages/top.vue?macro=true";
import { default as indexVb2iXXmvv4Meta } from "D:/DEV/CS2/web/pages/user/index.vue?macro=true";
import { default as vipg6jkMWYvdDMeta } from "D:/DEV/CS2/web/pages/vip.vue?macro=true";
export default [
  {
    name: "bans___lt",
    path: "/bans",
    component: () => import("D:/DEV/CS2/web/pages/bans/index.vue")
  },
  {
    name: "bans___en",
    path: "/en/bans",
    component: () => import("D:/DEV/CS2/web/pages/bans/index.vue")
  },
  {
    name: "bans-list___lt",
    path: "/bans/list",
    component: () => import("D:/DEV/CS2/web/pages/bans/list.vue")
  },
  {
    name: "bans-list___en",
    path: "/en/bans/list",
    component: () => import("D:/DEV/CS2/web/pages/bans/list.vue")
  },
  {
    name: "bans-muted___lt",
    path: "/bans/muted",
    component: () => import("D:/DEV/CS2/web/pages/bans/muted.vue")
  },
  {
    name: "bans-muted___en",
    path: "/en/bans/muted",
    component: () => import("D:/DEV/CS2/web/pages/bans/muted.vue")
  },
  {
    name: "bans-warnings___lt",
    path: "/bans/warnings",
    component: () => import("D:/DEV/CS2/web/pages/bans/warnings.vue")
  },
  {
    name: "bans-warnings___en",
    path: "/en/bans/warnings",
    component: () => import("D:/DEV/CS2/web/pages/bans/warnings.vue")
  },
  {
    name: "index___lt",
    path: "/",
    component: () => import("D:/DEV/CS2/web/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("D:/DEV/CS2/web/pages/index.vue")
  },
  {
    name: "payment___lt",
    path: "/payment",
    component: () => import("D:/DEV/CS2/web/pages/payment.vue")
  },
  {
    name: "payment___en",
    path: "/en/payment",
    component: () => import("D:/DEV/CS2/web/pages/payment.vue")
  },
  {
    name: "servers___lt",
    path: "/servers",
    component: () => import("D:/DEV/CS2/web/pages/servers.vue")
  },
  {
    name: "servers___en",
    path: "/en/servers",
    component: () => import("D:/DEV/CS2/web/pages/servers.vue")
  },
  {
    name: "skins___lt",
    path: "/skins",
    component: () => import("D:/DEV/CS2/web/pages/skins/index.vue")
  },
  {
    name: "skins___en",
    path: "/en/skins",
    component: () => import("D:/DEV/CS2/web/pages/skins/index.vue")
  },
  {
    name: "top___lt",
    path: "/top",
    component: () => import("D:/DEV/CS2/web/pages/top.vue")
  },
  {
    name: "top___en",
    path: "/en/top",
    component: () => import("D:/DEV/CS2/web/pages/top.vue")
  },
  {
    name: "user___lt",
    path: "/user",
    component: () => import("D:/DEV/CS2/web/pages/user/index.vue")
  },
  {
    name: "user___en",
    path: "/en/user",
    component: () => import("D:/DEV/CS2/web/pages/user/index.vue")
  },
  {
    name: "vip___lt",
    path: "/vip",
    component: () => import("D:/DEV/CS2/web/pages/vip.vue")
  },
  {
    name: "vip___en",
    path: "/en/vip",
    component: () => import("D:/DEV/CS2/web/pages/vip.vue")
  }
]