import type { ISkin } from '~/types/skins';
import type { IFetchResError, IPlayerSkins, IPlayerSkinsRes, IToast } from '~/types/types';

interface IAxisData {
  min: number;
  max: number;
}

export default {
  price: {
    formatted(cost: number) {
      const userLocale = navigator?.languages && navigator?.languages.length ? navigator?.languages[0] : navigator?.language || 'en-US';
      const formatter = new Intl.NumberFormat([ userLocale, 'en-US' ], {
        style: 'currency',
        currency: 'EUR',
      });
      return formatter.format(cost);
    },
  },
  time: {
    formatYMD(date: Date | number | string) {
      if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
      }
      return date.toISOString().split('T')[0];
    },
    suspensionDuration(date1: Date | number | string, date2: Date | number | string) {
      if (typeof date1 === 'number' || typeof date1 === 'string') {
        date1 = new Date(date1);
      }
      if (typeof date2 === 'number' || typeof date2 === 'string') {
        date2 = new Date(date2);
      }
      const diffInMins = Math.ceil(Math.abs(+date1 - +date2) / (1000 * 60));
      if (diffInMins < 60) {
        return `${diffInMins} minutes`;
      }
      if (diffInMins < (60 * 24)) {
        return `${Math.ceil(diffInMins / 60)} hours`;
      }
      return `${Math.ceil(diffInMins / 60 / 24)} days`;
    },
    formatPlaytime(time: number) {
      if (time < 60) {
        return `${time} sec`;
      }
      const days = Math.floor(time / 86400);
      const hours = Math.floor((time % 86400) / 3600);
      const minutes = Math.floor((time % 3600) / 60);

      let timeStr = '';
      if (days) {
        timeStr += `${days} d, `;
      }
      timeStr += `${hours} val, ${minutes} min`;
      return timeStr;
    },
  },
  chart: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    areaChart(title: string, yaxis?: IAxisData, xaxis?: IAxisData) {
      return {
        chart: {
          id: `area-${title.toLowerCase().replaceAll(' ', '-')}`,
          type: 'area',
          zoom: {
            enabled: false,
            autoScaleYaxis: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [ 0, 90, 100 ],
          },
        },
        yaxis: {
          min: yaxis?.min || 0,
          max: yaxis?.max || 1,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          x: {
            format: 'dd MMM HH:mm',
          },
        },
      };
    },
  },
  error: {
    handleFetchError(data: IFetchResError) {
      const { $store } = useNuxtApp();
      const errorData: IToast = {
        type: 'error',
        title: data?.message || 'Error',
      };

      if (data?.operationId) {
        errorData.errorId = data.operationId;
      }
      if (data?.message) {
        errorData.message = data.details;
      }
      $store.index.toast.push(errorData);
      setTimeout(() => {
        $store.index.toast.shift();
      }, 10000);
    },
  },
  getDefIndex(weaponId: string): number | null {
    const weaponIdx = Object.values(objects.weapons).findIndex(wpn => wpn.id === weaponId);
    if (weaponIdx > -1) {
      return parseInt(Object.keys(objects.weapons)[weaponIdx]);
    }
    const knifeIdx = Object.values(objects.knives).findIndex(knife => knife.id === weaponId);
    if (knifeIdx > -1) {
      return parseInt(Object.keys(objects.knives)[knifeIdx]);
    }
    return null;
  },
  mapPlayerSkins(playerSkins: IPlayerSkinsRes, skins: ISkin[]): IPlayerSkins {
    const { $store } = useNuxtApp();
    return {
      weapons: playerSkins.skins
        .filter(skin => skin.weapon_defindex in objects.weapons)
        .map(skin => {
          const weaponId = objects.weapons[skin.weapon_defindex as keyof typeof objects.weapons].id;
          return {
            team: skin.weapon_team,
            wear: skin.weapon_wear,
            seed: skin.weapon_seed,
            stattrak: skin.weapon_stattrak,
            nametag: skin.weapon_nametag,
            weaponId,
            skin: skins.find(({ paint_index, weapon: { id } }) => id === weaponId && skin.weapon_paint_id === +(paint_index || -1)),
          };
        }),
      knives: playerSkins.skins
        .filter(skin => skin.weapon_defindex in objects.knives)
        .filter(skin => playerSkins!.knife.findIndex(({ knife }) => knife === objects.knives[skin.weapon_defindex as keyof typeof objects.knives].id) > -1)
        .map(skin => {
          const weaponId = objects.knives[skin.weapon_defindex as keyof typeof objects.knives].id;
          return {
            team: skin.weapon_team,
            wear: skin.weapon_wear,
            seed: skin.weapon_seed,
            stattrak: skin.weapon_stattrak,
            nametag: skin.weapon_nametag,
            weaponId,
            skin: skins.find(({ paint_index, weapon: { id } }) => id === weaponId && skin.weapon_paint_id === +(paint_index || -1)),
          };
        }),
      gloves: $store.index.gloves.length ? playerSkins.skins
        .filter(skin => skin.weapon_defindex > 4700)
        .filter(skin => playerSkins.gloves?.weapon_defindex === skin.weapon_defindex)
        .map(skin => {
          const gloves = $store.index.gloves.find(g => g.defIndex === skin.weapon_defindex && g.paint_id === skin.weapon_paint_id)!;
          return {
            seed: skin.weapon_wear,
            team: skin.weapon_team,
            wear: skin.weapon_wear,
            skin: {
              id: `gloves-${skin.weapon_defindex}-${skin.weapon_paint_id}`,
              image: gloves.image,
              defIndex: skin.weapon_defindex,
              name: gloves.name,
              paint_id: +skin.weapon_paint_id,
              rarity: {
                name: 'Covert',
                color: '#eb4b4b',
              },
            },
          };
        })[0] : null,
      musicKit: $store.index.musicKits.length && !!playerSkins.musicKit?.music_id && $store.index.musicKits.find(kit => kit.id === `music_kit-${playerSkins.musicKit!.music_id}`) || null,
      collectible: $store.index.collectibles.length && !!playerSkins.pin?.id && $store.index.collectibles.find(pin => pin.id === `collectible-${playerSkins.pin!.id}`) || null,
      agents: $store.index.agents.length && playerSkins.agent && {
        terrorist: playerSkins.agent.agent_t && $store.index.agents.find(agent => agent.model_player.includes(playerSkins.agent!.agent_t!)) || null,
        counter: playerSkins.agent.agent_ct && $store.index.agents.find(agent => agent.model_player.includes(playerSkins.agent!.agent_ct!)) || null,
      } || { terrorist: null, counter: null },
    };
  },
};
